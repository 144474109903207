import React, { ComponentProps, PropsWithChildren } from "react";
import { styled } from "../../../stitches.config";
import { IS_LOGO_NAV_SPECIAL } from '../../../components/common/constants/constants';

import Logo from "../../atoms/Logo";

import LogoSpecial from "../../assets/logos/special/logo_192x82.webp";


const NavLogo: React.FC<PropsWithChildren<ComponentProps<typeof LogoComponent>>> = ({ ...props }) => {

    if (IS_LOGO_NAV_SPECIAL) {
        props.children = (
            <img src={LogoSpecial} />
        );
        props.brand = "special";
    }

    return (
        <LogoComponent
            {...props}
        />
    );

};

const LogoComponent = styled(Logo, {
    height: 52,
    "& svg": {
        height: 18
    },
    "@bp4": {
        width: 192,
        height: 60,
    },
    variants: {
        brand: {
            'topnewsy': {
                "& svg": {
                    height: '28px !important'
                }
            },
            'klubzwierzaki':{
                "& svg": {
                    height: '48px !important'
                }
            },
            'special': {
                display: 'block',
                marginLeft: "0 !important",
                "img": {
                    width: '100%',
                    height: '100%',
                    objectPosition: 'left center',
                    objectFit: 'contain'
                },
            }
        },
        isFullNav: {
            true: {
                height: 51,
                "@bp4": {
                    width: 192,
                    height: 83,
                },
            },
            false: {}
        }
    },
});

export default NavLogo;